import React, { useState, useEffect } from "react";
import { login } from "../../api/auth";
import PageWrapper from "../../components/PageWrapper";
import "./Login.css";
import { validateQueryParams } from "../../lib/auth";
import ErrorMessage from "../../components/ErrorMessage";
import { MdError } from "react-icons/md";
import { toast } from "react-toastify";

// This page is auth-service specific only!

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [queryParamsAreValid, setQueryParamsAreValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
    } catch (error) {
      toast.error("Failed to login");
      console.error("Login failed", error);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Only validate email if the value has changed
    if (value === "" || /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(value)) {
      setIsEmailValid(true);
      setEmailErrorMessage("");
    } else {
      setIsEmailValid(false);
      setEmailErrorMessage("Please enter a valid email address.");
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Only validate password if the value has changed
    if (value === "" || value.length >= 8) {
      setIsPasswordValid(true);
      setPasswordErrorMessage("");
    } else {
      setIsPasswordValid(false);
      setPasswordErrorMessage("Password must be at least 8 characters long.");
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setQueryParamsAreValid(validateQueryParams(params));
  }, [queryParamsAreValid]);

  return (
    <PageWrapper>
      <div className="login-container">
        <h2>Login</h2>
        {queryParamsAreValid ? (
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
                //pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title="Please enter a valid email address"
                className={isEmailValid ? "valid" : "invalid"}
              />
              {!isEmailValid && (
                <p className="error-message">{emailErrorMessage}</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                required
                minLength="8"
                title="Password must be at least 8 characters long"
                className={isPasswordValid ? "valid" : "invalid"}
              />
              {!isPasswordValid && (
                <p className="error-message">{passwordErrorMessage}</p>
              )}
            </div>
            <button
              type="submit"
              className={`login-button ${!canSubmitLoginForm() && "disabled"}`}
              disabled={!canSubmitLoginForm()}
            >
              Login
            </button>
          </form>
        ) : (
          <ErrorMessage>
            <MdError className="error-icon" />
            <p>Bad login state</p>
          </ErrorMessage>
        )}
      </div>
    </PageWrapper>
  );

  function canSubmitLoginForm() {
    return (
      email &&
      password &&
      queryParamsAreValid &&
      isEmailValid &&
      isPasswordValid
    );
  }
};

export default Login;
