import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import OauthAuthorize from "./pages/OAuthAuthorize";
import ENV from "./lib/config";
import NotFound from "./pages/NotFound";
import Registration from "./pages/Registration";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Routes>
            {/* OAuth2 routes */}
            <Route
              path={`${ENV.get().URI_PREFIX || ""}/oauth/login`}
              element={<Login />}
            />
            <Route
              path={`${ENV.get().URI_PREFIX || ""}/oauth/authorize`}
              element={<OauthAuthorize />}
            />
            <Route
              path={`${ENV.get().URI_PREFIX || ""}/register`}
              element={<Registration />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
